import React, { useState } from 'react';
import HandleModal from '../BlurModal';
import Api from '@/api/Api';
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import dogWalking from '@public/animations/dog-walking.json';
import welcome from '@public/animations/welcome.json';
import Notify from '@/helpers/Notify';
import { useMember } from '@/contexts/member/MemberContext';
import ActionButton from '@/components/modals/login/partials/ActionButton';
import EmailSender from '@/classes/EmailSender';
import EpjLoadingIcon from '@/components/animations/EpjLoadingIcon';
import EpjLoadingAdapteIcon from '@/components/animations/EpjLoadingAdapteIcon';

// Responsáveis pelo cadastro dos novos clientes.
type Responsible = { name: string, email: string };

const registrationResponsible: Array<Responsible> = [
	{ name: 'Guilherme Lourenço', email: 'lourencoguilherme96@gmail.com' },
	{ name: 'Lorrami Oliveira', email: 'ti@epraja.com.br' },
	{ name: 'Clayton Gonçalves', email: 'comercial@salestag.com.br' },
];

interface RegistrationModalProps {
	isOpen: boolean;
	onClose: () => void;
}

interface LoginPanelProps {
}

type FieldType = "name" | "email" | "phone" | "email" | "companyName" | "document" | "about";

type Field = {
	inputType: "text" | "email" | "phone";
	inputId: string;
	label: string;
	value: string;
	onChange: (value: string) => void;
	inputStyle?: React.CSSProperties
	textArea?: boolean
}

type Step = {
	label: string,
	fields: Array<FieldType>
}

const RegistrationModal: React.FC<RegistrationModalProps> = ({ isOpen, onClose }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [email, setEmail] = useState<string>('');
	const [clientName, setClientName] = useState<string>('');
	const [document, setDocument] = useState<string>('');
	const [phone, setPhone] = useState<string>('');
	const [companyName, setCompanyName] = useState<string>('');
	const [about, setAbout] = useState<string>('');

	const [concludeRegistration, setConcludeRegistration] = useState<boolean>(false);

	const {
		setIsLoginModalVisible
	} = useMember();

	const [currentStep, setCurrentStep] = useState(0);

	const phoneMask = (phoneNumber: string) => {
		const parsedNumber = phoneNumber.replace(/\D/g, '');
		let maskedNumber = '';

		if (parsedNumber.length >= 2) {
			maskedNumber = `(${parsedNumber.slice(0, 2)}`;
		} else {
			maskedNumber = parsedNumber;
		}

		if (parsedNumber.length >= 7) {
			maskedNumber += `) ${parsedNumber.slice(2, 7)}`;
		} else if (parsedNumber.length >= 2) {
			maskedNumber += `) ${parsedNumber.slice(2)}`;
		}

		if (parsedNumber.length >= 11) {
			maskedNumber += `-${parsedNumber.slice(7, 11)}`;
		} else if (parsedNumber.length >= 7) {
			maskedNumber += `-${parsedNumber.slice(7)}`;
		}

		setPhone(maskedNumber);
	};

	const steps: Array<Step> = [
		{ label: 'Informações de Contato', fields: ['name', 'email', 'phone'] },
		{ label: 'Outras Informações', fields: ['companyName', 'about'] },
	];

	const fields: Record<FieldType, Field> = {
		email: {
			inputType: 'email',
			inputId: 'emailAddress',
			label: 'Email',
			value: email,
			onChange: setEmail
		},
		phone: {
			inputType: 'phone',
			inputId: 'phone',
			label: 'Telefone de Contato',
			value: phone,
			onChange: phoneMask
		},
		name: {
			inputType: 'text',
			inputId: 'name',
			label: 'Nome do Responsável',
			value: clientName,
			onChange: setClientName
		},
		companyName: {
			inputType: 'text',
			inputId: 'companyName',
			label: 'Nome da Empresa',
			value: companyName,
			onChange: setCompanyName
		},
		document: {
			inputType: 'text',
			inputId: 'document',
			label: 'Documento',
			value: document,
			onChange: setDocument
		},
		about: {
			inputType: 'text',
			inputId: 'about',
			label: 'Conte-nos do que sua empresa precisa',
			value: about,
			onChange: setAbout,
			inputStyle: { height: 150 },
			textArea: true
		}
	}

	const sendEmail = () => {
		if (!clientName || !email) return;

		const name = clientName.split(' ')[0] || clientName;
		const mailContent = `
			<div style="width: 100%; text-align: center; padding-top: 25px; padding-bottom: 25px;">
				<span style="font-size: 25px; display: block; margin-bottom: 35px;">
					Olá, <b>${name}</b>,
				</span>
				<span style="font-size: 16px; display: block;">
					Estamos muito felizes pelo seu interesse em nossa solução.<br/>
					Recebemos seus dados de contato e em breve nossa equipe comercial entrará em contato com você.
				</span>
			</div>
		`;

		// Email para o lead.
		EmailSender.sendEmail({
			to: email,
			subject: 'Obrigado por entrar em contato!',
			html: EmailSender.generateHTMLEmailContent(mailContent)
		});

		// Email para o comercial
		registrationResponsible.forEach((responsible) => {
			const responsibleName = responsible.name.split(' ')[0] || responsible.name;
			const mailContent = `
				<div style="width: 100%; text-align: center; padding-top: 25px; padding-bottom: 25px;">
					<span style="font-size: 25px; display: block; margin-bottom: 35px;">
						Olá, <b>${responsibleName}</b>,
					</span>
					<span style="font-size: 16px; display: block; margin-bottom: 15px;">
						<b>${clientName}</b> da empresa <b>${companyName}</b> está interessado em nossa solução de cartazeamento!<br/>
					</span>
					<span style="font-size: 16px; display: block; margin-bottom: 15px;">
						"<small>${about}</small>"<br/>
					</span>
					<span style="font-size: 16px; display: block; margin-bottom: 15px;">
						<b>Telefone</b>: ${phone}<br/>
					</span>
					<span style="font-size: 16px; display: block; margin-bottom: 15px;">
						<b>Email:</b>: ${email}<br/>
					</span>
					<span style="font-size: 16px; display: block; margin-bottom: 15px;">
						Não deixe-o esperando, acesse agora o Tablóide para ver mais detalhes.
					</span>
				</div>
			`;
			EmailSender.sendEmail({
				to: responsible.email,
				subject: 'Novo Lead aguardando!',
				html: EmailSender.generateHTMLEmailContent(mailContent)
			});
		});
	}

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		// Validação de número de telefone
		if (!validatePhoneNumber(phone)) {
			Notify.Warn("Por favor, insira um número válido de telefone");
			return;
		}
		// Avança para o próximo passo
		if (currentStep < steps.length - 1) {
			setCurrentStep(currentStep + 1);
			return;
		}
		// Realiza envio do formulário
		const api = new Api('registration', 'c');
		setIsLoading(true);
		const request = {
			clientName,
			email,
			phone,
			companyName,
			about
		}
		const response = await api.post(request);
		if (response.success) {
			Notify.Success("Cadastro realizado com sucesso!");
			sendEmail(); // Envia email de confirmação.
		}
		setConcludeRegistration(true);
		setIsLoading(false);
	};

	const onCancel = () => {
		onClose();
		setIsLoginModalVisible(true);
	}

	const goBack = () => {
		if (currentStep > 0) setCurrentStep(currentStep - 1);
		else onCancel();
	}

	return (
		<HandleModal isOpen={isOpen} onClose={onCancel} >
			<div className="min-h-fit flex items-center justify-center bg-gray-100 relative">
				<div className="bg-white p-8 rounded-lg w-[56rem] h-[36rem] flex flex-row">
					<div className="w-1/2 h-full flex flex-col items-center justify-center">
						<LoginPanel />
					</div>
					<div className="w-1/2 h-full flex flex-col items-center mt-20 ">

						<h1 className="text-2xl font-semibold mb-6 cursor-default">
							<span className='text-orange-500 hover:underline'>
								Informações de Contato
							</span>
						</h1>
						{
							isLoading ? (
								<div className="flex justify-center items-center w-full -my-3">
									<div className="w-[100px] h-[100px]" >
										<EpjLoadingAdapteIcon />
									</div>
								</div>
							) : concludeRegistration ? <ConcludeRegistrationMessage {...{ clientName, onClose }} /> : (
								<form onSubmit={handleSubmit} className="w-full p-6">
									{
										steps[currentStep].fields.map((fieldName) => {
											const currentField = fields[fieldName];
											const props = {
												id: currentField.inputId,
												type: currentField.inputType,
												value: currentField.value,
												disabled: isLoading,
												onChange: (e: any) => currentField.onChange(e.target.value),
												style: currentField.inputStyle || {},
												className: "w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded focus:outline-none focus:border-orange-500 hover:border-orange-500",
												required: true
											}
											return (
												<div className="mb-4" key={currentField.inputId}>
													<label htmlFor={currentField.inputId} className="block text-gray-700 mb-2">
														{currentField.label}
													</label>
													{currentField.textArea === true ? <textarea {...props} /> : <input {...props} />}
												</div>
											)
										})
									}
									<div className="flex justify-between items-center mx-auto">
										<ActionButton label={currentStep === 0 ? 'Cancelar' : 'Voltar'} type='button' variant='secondary' onClick={goBack} disabled={isLoading} />
										<ActionButton
											label={currentStep === steps.length - 1 ? 'Enviar' : 'Avançar'}
											type="submit"
											variant="primary"
											disabled={isLoading}
										/>
									</div>
								</form>
							)
						}
					</div>
				</div>
			</div>
		</HandleModal>
	);
}

export default RegistrationModal;

const LoginPanel: React.FC<LoginPanelProps> = ({ }) => {
	return (
		<div className="h-full w-full flex flex-col items-center justify-center cursor-default">
			<span className='text-2xl text-center mb-4' >Entre em contato para um teste gratuito</span>
			<Lottie
				loop
				play
				animationData={welcome}
			/>
			<span className='mt-4 text-lg' >Venha para o <b>Salestag</b>!</span>
		</div>
	);
}

const ConcludeRegistrationMessage: React.FC<{ clientName: string, onClose: () => void }> = ({ clientName, onClose }) => {
	const name = clientName.split(' ')[0] || clientName;
	return (
		<div className='flex flex-col h-full w-full pb-4'>
			<span className='h-2/3 mt-4'><b>{name}</b>, obrigado por se interessar pelo Salestag Tablóide. Em breve nossa equipe comercial entrará em contato!</span>
			<ActionButton label='Fechar' type='button' variant='secondary' onClick={onClose} />
		</div>
	)
}

function validatePhoneNumber(number: string): boolean {
	const regex = /^\(?\d{2}\)?[-. ]?\d{4,5}[-. ]?\d{4}$/;
	return regex.test(number);
}
